.classroom-code-notification-link-container {
  background: #FFDED1;
}
.classroom-code-notification-link-container .notification-container {
  text-align: center;
  padding: 14px;
  font-family: TTNorms-medium;
}
@media (max-width: 359px) {
  .classroom-code-notification-link-container .notification-container .message.desktop {
    display: none;
  }
}
.classroom-code-notification-link-container .notification-container .message.mobile {
  display: none;
}
@media (max-width: 359px) {
  .classroom-code-notification-link-container .notification-container .message.mobile {
    display: block;
  }
}
.classroom-code-notification-link-container .notification-container .message a {
  text-decoration: underline;
}
