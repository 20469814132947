@import (reference) "less/variables.less";
@import (reference) "styles/screen-sizes.less";

.classroom-code-notification-link-container {
  background: #FFDED1;

  .notification-container {
    text-align: center;
    padding: 14px;
    font-family: TTNorms-medium;

    .message {

      &.desktop {
        @media (max-width: (@screen-xs - 1)) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @media (max-width: (@screen-xs - 1)) {
          display: block;
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}